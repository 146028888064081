export const config = {
	baseUrl: "https://back.karama.darrebni.net/api",
	allPlayers: "player/all",
	stuff: "stuff",
	allSeason: "seasone/all",
	standing: "standing",
	nextMatch: "next/matche",
	nextMatches: "matches/not/started",
	matchDetails: "matche/details",
	newsAll: "news/all",
	playersDetails: "player/details",
	associations: "associations",
	clubAll: "club/all",
	matchMainPlayers: "matche/main/players",
	tablePoint: "standing",
	addPlayer: "player/add",
	updatePlayer: "player/update",
	allSport: "sport/all",
	login: "login",
	logout: "logout",
	updateBosses: "employee/update",
	addBosses: "employee/add",
	contact: "send/email",
	matchNotStarted: "matches/finished",
	addInformation: "information/add",
	updateInformation: "information/update",
	sliders: "sliders/all",
	updateVideo: "video/update",
	addVideo: "video/add",
	addMatch: "matche/add",
	updateMatch: "./matche/update",
};
